import { Base64 } from 'js-base64'
import { trim, truncate } from 'lodash/string'
import sanitizeHtml from 'sanitize-html'

export default {
  methods: {
    buildTags (title, description, image, keywords, titleTemplate) {
      const desc = this.stripTags(description)
      const t = title || ''

      const meta = {
        title: t,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: desc
          },
          {
            property: 'og:title',
            content: t,
            hid: 'og:title'
          },
          {
            property: 'og:description',
            content: desc,
            hid: 'og:description'
          },
          {
            name: 'twitter:title',
            content: t,
            hid: 'twitter:title'
          },
          {
            name: 'twitter:description',
            content: desc,
            hid: 'twitter:description'
          }
        ]
      }

      if (image) {
        const img = this.getImageUrl(image)

        meta.meta.push(...[
          {
            name: 'twitter:image',
            content: img,
            hid: 'twitter:image'
          },
          {
            property: 'og:image',
            content: img,
            hid: 'og:image'
          },
          {
            property: 'og:image:secure_url',
            content: img,
            hid: 'og:image:secure_url'
          },
          {
            property: 'og:image:width',
            content: '320',
            hid: 'og:image:width'
          },
          {
            property: 'og:image:height',
            content: '320',
            hid: 'og:image:height'
          }
        ])
      }

      if (keywords) {
        meta.meta.push({
          hid: 'keywords',
          name: 'keywords',
          content: keywords
        })
      }

      if (titleTemplate) {
        meta.titleTemplate = titleTemplate
      }

      return meta
    },
    getImageUrl (avatar) {
      if (avatar) {
        const imageRequest = JSON.stringify({
          bucket: process.env.imagesBucket,
          key: avatar.fileName,
          edits: {
            rotate: null,
            resize: {
              width: 320,
              height: 320
            }
          }
        })
        return `${process.env.imagesURL}/${Base64.encode(imageRequest)}`
      }

      return require('@/assets/img/avatar-placeholder.png')
    },
    stripTags (content) {
      if (content) {
        return trim(truncate(sanitizeHtml(content.replace(/>/g, '> '), {
          allowedTags: []
        }), { length: 300 })).replace(/\s\s+/g, ' ')
      }
      return ''
    }
  }
}
