import { render, staticRenderFns } from "./AboutEn.vue?vue&type=template&id=869f2718&"
import script from "./AboutEn.vue?vue&type=script&lang=js&"
export * from "./AboutEn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AboutItem: require('/home/ubuntu/ct_frontend/components/about/AboutItem.vue').default})
