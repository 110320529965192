//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// noinspection JSUnusedGlobalSymbols
import FreelancersFunnel from '@/components/about/FreelancersFunnel'
import EmployersFunnel from '@/components/about/EmployersFunnel'

export default {
  name: 'AboutHr',
  components: {
    EmployersFunnel,
    FreelancersFunnel
  },
  computed: {
    isDark () {
      return this.$store.state.ui.isDarkThemeEnabled
    }
  }
}
