//
//
//
//
//
//

import AboutEn from '@/components/about/AboutEn'
import AboutHr from '@/components/about/AboutHr'
import SeoMethods from '@/mixins/SeoMethods'
import Pageview from '@/mixins/Pageview'

export default {
  name: 'AboutPage',
  components: {
    AboutEn,
    AboutHr
  },
  mixins: [SeoMethods, Pageview],
  layout: 'blank',
  head () {
    return this.buildTags(this.$t('meta.infoTitle'), this.$t('meta.infoDescription'), null, this.$t('meta.infoKeywords'))
  },
  computed: {
    body () {
      const currentLang = this.$store.state.i18n.locale

      if (currentLang) {
        return `about-${currentLang}`
      }

      return null
    }
  },
  auth: false
}
