//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'EmployersFunnel',
  computed: {
    isDark () {
      return this.$store.state.ui.isDarkThemeEnabled
    },
    locale () {
      return this.$store.state.i18n.locale
    }
  }
}
