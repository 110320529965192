//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AboutItem from '@/components/about/AboutItem'
import FreelancersFunnel from '@/components/about/FreelancersFunnel'
import EmployersFunnel from '@/components/about/EmployersFunnel'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'AboutEn',
  components: {
    EmployersFunnel,
    FreelancersFunnel,
    AboutItem
  },
  computed: {
    isDark () {
      return this.$store.state.ui.isDarkThemeEnabled
    }
  }
}
